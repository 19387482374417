import revive_payload_client_4sVQNw7RlN from "/Users/baidu/www/hibachi/web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/baidu/www/hibachi/web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/baidu/www/hibachi/web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/Users/baidu/www/hibachi/web/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/Users/baidu/www/hibachi/web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/baidu/www/hibachi/web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/baidu/www/hibachi/web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/baidu/www/hibachi/web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/baidu/www/hibachi/web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/Users/baidu/www/hibachi/web/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/Users/baidu/www/hibachi/web/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import titles_dw2T9lEw4h from "/Users/baidu/www/hibachi/web/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_aRl2YLnTcb from "/Users/baidu/www/hibachi/web/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_izaWKZ8rEu from "/Users/baidu/www/hibachi/web/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/Users/baidu/www/hibachi/web/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import unocss_MzCDxu9LMj from "/Users/baidu/www/hibachi/web/.nuxt/unocss.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/baidu/www/hibachi/web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import devtools_client_VUiMk8lZ8O from "/Users/baidu/www/hibachi/web/app/plugins/devtools.client.ts";
import youtube_client_7GcrA3A1XA from "/Users/baidu/www/hibachi/web/app/plugins/youtube.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  plugin_WLsn00x1qh,
  titles_dw2T9lEw4h,
  defaults_aRl2YLnTcb,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  unocss_MzCDxu9LMj,
  chunk_reload_client_UciE0i6zes,
  devtools_client_VUiMk8lZ8O,
  youtube_client_7GcrA3A1XA
]