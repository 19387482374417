import { default as _91id_93sWvWFM4mZmMeta } from "/Users/baidu/www/hibachi/web/app/pages/adopt/[id].vue?macro=true";
import { default as indexdpkJde02P7Meta } from "/Users/baidu/www/hibachi/web/app/pages/adopt/index.vue?macro=true";
import { default as _91id_939ZOMyoxG1MMeta } from "/Users/baidu/www/hibachi/web/app/pages/articles/[id].vue?macro=true";
import { default as indexTSZOZbmHOOMeta } from "/Users/baidu/www/hibachi/web/app/pages/articles/index.vue?macro=true";
import { default as _91slug_936mbrs93UjDMeta } from "/Users/baidu/www/hibachi/web/app/pages/blog/[slug].vue?macro=true";
import { default as index3n2DM3LwujMeta } from "/Users/baidu/www/hibachi/web/app/pages/blog/index.vue?macro=true";
import { default as _91location_93kjB7rxXB1RMeta } from "/Users/baidu/www/hibachi/web/app/pages/book/[location].vue?macro=true";
import { default as bookingxROyHMPrp0Meta } from "/Users/baidu/www/hibachi/web/app/pages/booking.vue?macro=true";
import { default as faqP7aAkIJyV1Meta } from "/Users/baidu/www/hibachi/web/app/pages/faq.vue?macro=true";
import { default as indexMiaeoNuFaoMeta } from "/Users/baidu/www/hibachi/web/app/pages/index.vue?macro=true";
import { default as locationsjSly4EFb2QMeta } from "/Users/baidu/www/hibachi/web/app/pages/locations.vue?macro=true";
import { default as _91_46_46_46slug_93pDkGWe64h8Meta } from "/Users/baidu/www/hibachi/web/app/pages/quick-start/[...slug].vue?macro=true";
import { default as volunteerZCl4wILkTeMeta } from "/Users/baidu/www/hibachi/web/app/pages/volunteer.vue?macro=true";
export default [
  {
    name: "adopt-id",
    path: "/adopt/:id()",
    meta: _91id_93sWvWFM4mZmMeta || {},
    component: () => import("/Users/baidu/www/hibachi/web/app/pages/adopt/[id].vue").then(m => m.default || m)
  },
  {
    name: "adopt",
    path: "/adopt",
    meta: indexdpkJde02P7Meta || {},
    component: () => import("/Users/baidu/www/hibachi/web/app/pages/adopt/index.vue").then(m => m.default || m)
  },
  {
    name: "articles-id",
    path: "/articles/:id()",
    meta: _91id_939ZOMyoxG1MMeta || {},
    component: () => import("/Users/baidu/www/hibachi/web/app/pages/articles/[id].vue").then(m => m.default || m)
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexTSZOZbmHOOMeta || {},
    component: () => import("/Users/baidu/www/hibachi/web/app/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_936mbrs93UjDMeta || {},
    component: () => import("/Users/baidu/www/hibachi/web/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: index3n2DM3LwujMeta || {},
    component: () => import("/Users/baidu/www/hibachi/web/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "book-location",
    path: "/book/:location()",
    meta: _91location_93kjB7rxXB1RMeta || {},
    component: () => import("/Users/baidu/www/hibachi/web/app/pages/book/[location].vue").then(m => m.default || m)
  },
  {
    name: "booking",
    path: "/booking",
    meta: bookingxROyHMPrp0Meta || {},
    component: () => import("/Users/baidu/www/hibachi/web/app/pages/booking.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqP7aAkIJyV1Meta || {},
    component: () => import("/Users/baidu/www/hibachi/web/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexMiaeoNuFaoMeta || {},
    component: () => import("/Users/baidu/www/hibachi/web/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "locations",
    path: "/locations",
    meta: locationsjSly4EFb2QMeta || {},
    component: () => import("/Users/baidu/www/hibachi/web/app/pages/locations.vue").then(m => m.default || m)
  },
  {
    name: "quick-start",
    path: "/quick-start/:slug(.*)*",
    meta: _91_46_46_46slug_93pDkGWe64h8Meta || {},
    component: () => import("/Users/baidu/www/hibachi/web/app/pages/quick-start/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "volunteer",
    path: "/volunteer",
    meta: volunteerZCl4wILkTeMeta || {},
    component: () => import("/Users/baidu/www/hibachi/web/app/pages/volunteer.vue").then(m => m.default || m)
  }
]